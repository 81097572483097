<template>
    <div class="container" style="display: flex;height: 100vh; align-items:center; justify-content:center;">
        <div style="display: flex; width: 100%;  align-items:center; justify-content:center;">
            <div
                style="text-align: center; display: flex; flex-direction:column; align-items:center; justify-content:center; ">
                <h2 style="font-family: poppins;">¡Bienvenido a Mundo Universitario!</h2> 
                <h3 style="font-family: poppins;"> Tu cuenta ha sido confirmada</h3>
                <br>
                <!-- <span style="font-size: 18px; justify-content: center;">Nos alegra que estés aquí, Mundo Universitario
                    es una comunidad que conecta a los estudiantes y egresados con las empresas para lograr un
                    desarrollo profesional.
                    <br>
                    Te invitamos a terminar tu registro para poder acceder a todas las funcionalidades.
                    <br><br>
                </span> -->
                <router-link to="/login" style=" color:#f5782d; font-weight: bold; text-decoration: none; font-size: 22px;">INICIAR SESIÓN</router-link>
            </div>

        </div>
    </div>
</template>

<script>
export default {
};

</script>
  
<style land="scss" scoped></style>
  